<template>
  <div class="tourismmana">
    <Header class="hide" />
    <Headers class="dispy" />
    <div class="bar">
      <BannerImage rulename="tourismmana_banner" />
      <div class="w">
        <Breadcrumb :crumblist="crumbdata" class="hide" />
        <div class="deti">
          <h3 class="title">{{ result.names }}</h3>
          <div class="desc" v-html="result.cont"></div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import { loadConfig, loadPictureConfig , loadSingleConfig} from "@/api/api";
import { getAction } from "@/api/manage";
import BannerImage from "@/components/main/BannerImage";
export default {
  components: {
    Header,
    Headers,
    Footer,
    Breadcrumb,
    BannerImage,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      crumbdata: [
        { name: "旅游管理", url: "" },
        { name: "旅游管理机构", url: "/tourismmana" },
      ],
      result: "",
    };
  },
  methods: {
    loadData() {
		loadSingleConfig({
        rule: "cx_tourismmana",
      }).then((res) => {
        if (res.success) {
          this.result = res.result;
        }
      });
	},
  },
};
</script>
<style lang="scss" scoped>
.deti {
  padding: 20px 0 50px;
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: normal;
    color: #333333;
    margin-bottom: 30px;
  }
  .desc {
    width: 1093px;
    margin: 0 auto;
    color: #666666;
    text-align: justify;
    min-height: 500px;
    p {
      img {
        display: block;
        width: 90% !important;
        margin: 0 auto;
      }
    }
  }
}
</style>
